<template>
    <div>
        <div style="display:inline-block;width:100%;height:100%;">
            <div class="child-top">
                <!--                单位设置-->
                <div class="input-from">
                    <el-input
                            @keyup.enter.native="getData(true)"
                            class="left-child-input left-child-input-content"
                            clearable
                            placeholder="请输入名称"
                            style="width:200px;float:left;"
                            v-model="input4"
                    >
                        <el-button @click="getData(true)" icon="iconfont iconsousuo" slot="append"
                                   style="color: #fff;"></el-button>
                    </el-input>
                </div>
                <div class="operation-button">
                    <el-button
                            @click="addDataLog"
                            :disabled="$store.getters.permissionsStr('新增')"
                            class="brotherBtns" style="border-right:0;">
                        <i class="iconfont icon-xinjian-icon" style="color:#4BB3FF;"></i>
                        <span>新增</span>
                    </el-button>
                    <!--                    :class="$store.getters.permissionsStr('删除') ? 'disabledRed' : 'noDisabledRed'"-->
                    <!--                    :disabled="tableDataSelectArr.length === 0 || $store.getters.permissionsStr('删')"-->
                    <el-button
                            :disabled="tableDataSelectArr.length === 0 || $store.getters.permissionsStr('删除')"
                            @click="delMoreData"
                            class="brotherBtns"
                    >
                        <i class="iconfont icon-shanchu-icon iconFontSize" style="color:#FF6666;"></i>
                        <span>删除</span>
                    </el-button>
                </div>
            </div>
            <div class="content-flex-pages">
                <el-table
                        :data="tableData"
                        @selection-change="tableSelectionChange"
                        class="content-test-table"
                        style="width: 100%"
                        v-loading="tableLoading"
                >
                    <el-table-column type="selection" width="45"></el-table-column>
                    <el-table-column
                            label="序号"
                            type="index"
                            width="45">
                        <template slot-scope="scope">
                            {{(currentPage-1)*10+scope.$index+1}}
                        </template>
                    </el-table-column>
                    <el-table-column label="名称" prop="cn_name" show-overflow-tooltip></el-table-column>
                    <el-table-column label="顺序" prop="order_no" show-overflow-tooltip></el-table-column>
                    <el-table-column label="备注" prop="demo" show-overflow-tooltip></el-table-column>
                    <el-table-column label="状态" prop="status" show-overflow-tooltip width="80">
                        <template slot-scope="scope">
                            <el-switch
                                    active-color="#0055E9"
                                    :active-value="1"
                                    :disabled="$store.getters.permissionsStr('启/停用')"
                                    :inactive-value="0"
                                    :width="35"
                                    @change="ChangeState(scope.row)"
                                    inactive-color="#ccc"
                                    v-model=scope.row.status
                            ></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button
                                    @click="editorData(scope.row,scope.$index)"
                                    v-show="!$store.getters.permissionsStr('编辑')"
                                    class="right20"
                                    size="medium"
                                    style="color: #0055E9;"
                                    type="text"
                            >编辑
                            </el-button>
                            <!-- #F5A623 -->
                            <el-button
                                    @click="delTableData(scope.row)"
                                    v-show="!$store.getters.permissionsStr('删除')"
                                    class="redColor"
                                    size="medium"
                                    style="color:#FD3258;"
                                    type="text"

                            >删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        :current-page.sync="currentPage"
                        :page-size="10"
                        :total="tableTotal"
                        @current-change="handleCurrentChange"
                        class="table-pagination"
                        layout="total, prev, pager, next , jumper"
                ></el-pagination>
            </div>
            <!--            新增彈窗-->
            <el-dialog :close-on-click-modal="false" :visible.sync="dialogTableVisible"
                       title="" width='30%'>
                <div class="ModifyDoalog">
                    <p>{{!dialogTableVisibleType ? '新增' : '编辑'}}指标单位</p>
                    <el-form :label-position="'left'" :model="form" :rules="rules" label-width="80px" ref="form">
                        <el-form-item label="名称" prop="cn_name">
                            <el-input v-model="form.cn_name"></el-input>
                        </el-form-item>

                        <el-form-item label="顺序" prop="order_no">
                            <el-input v-model="form.order_no"></el-input>
                        </el-form-item>
                        <el-form-item label="备注">
                            <el-input v-model="form.demo"></el-input>
                        </el-form-item>
                        <div class="form-bottom-button">
                            <el-button @click.native="save" type="primary" v-no-more-click>保存</el-button>
                            <el-button @click="dialogTableVisible = false">取消</el-button>
                        </div>
                    </el-form>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    let data = {
        cn_name: "", //姓名
        demo: "", //备注
        order_no: "", //顺序
    };

    let dataNext = {
        mobile: "", //手机号
        email: "", //邮箱
        emergency_name1: "", //紧急联系人1名称
        emergency_relation1: "", //紧急联系人1关系
        emergency_contact1: "", //紧急联系人1手机号
        emergency_name2: "",
        emergency_relation2: "",
        emergency_contact2: ""
    };

    export default {
        data() {
            return {
                currentPage: 1,
                tableTotal: 0,
                dialogTableVisible: false, // 新增弹窗
                tableLoading: false,
                dialogTableVisibleType: false, //是否是新增
                form: {
                    ...data,
                },
                tableData: [],
                status: '',      //启动状态的值
                selectArr: {
                    type: "",
                    project: "",
                    jobs: "",
                    sex: "",
                    inputType: "",
                    grade: '',
                },
                typeOptions: {
                    options: []
                },
                projectOptions: {
                    options: []
                },
                lationshipOptions: {
                    options: []
                },
                value: "",
                input4: "",
                addUserDialog: false,

                ruleForm: {
                    ...data
                },
                ruleFormNext: {
                    ...dataNext
                },
                rules: {
                    // cn_name: this.$rules.cn_name,
                    cn_name: {...this.$rules.noEmpty, message: "名称不能为空"},
                },
                imgData: null,
                tableDataSelectArr: [], //表格选中
            };
        },
        created() {
            this.getData()
        },
        watch: {
            // $route(to, from) {
            //     if (this.$route.query.uuid && this.sportType) {
            //         this.currentPage = 1;
            //         this.getData();
            //     }
            // }
        },
        mounted() {
        },
        methods: {
            getData(e) {
                this.tableLoading = true;
                this.$axios
                    .post(
                        "/p/unit/lists",
                        this.$qs({
                            page: e === true ? '' : this.currentPage,
                            cn_name: this.input4
                        })
                    )
                    .then(res => {
                        if (res.data.code === 0) {
                            this.tableData = res.data.data.lists;
                            this.tableTotal = res.data.data.total;
                            this.tableLoading = false;
                        }
                    });
                if (e) {
                    this.currentPage = 1
                }
            },
            addUserDialogClick() {
                this.addUserDialog = true;
                setTimeout(() => {
                    this.$dialogHeight();
                    this.$refs.ruleForm.resetFields();
                }, 0);
            },
            //新增
            addDataLog() {
                this.dialogTableVisibleType = false;
                this.dialogTableVisible = true;
                this.form = {
                    cn_name: "", //姓名
                    demo: "", //备注
                    order_no: "", //顺序
                };
                // this.roleData.stationValue = '';
                // console.log(this.roleData.stationValue);
                setTimeout(() => {
                    this.$dialogHeight();
                    this.$refs['form'].clearValidate();
                }, 100)
            },
            //新增的保存
            save() {
                let submit = () => {
                    //编辑
                    let arr = [];
                    // this.roleData.options.forEach((i,k)=>{
                    //     if(i.model){
                    //         arr.push(i.uuid)
                    //     }
                    // })
                    if (this.dialogTableVisibleType) {
                        delete this.form.role_name;
                        let data = {
                            uuid: this.form.uuid,
                            cn_name: this.form.cn_name,
                            demo: this.form.demo,
                            order_no: this.form.order_no
                        }
                        for (let i in data) {
                            if (data[i] === '') {
                                delete data[i]
                            }
                        }
                        this.$axios.post("/p/unit/update", this.$qs(data)).then((res) => {
                            if (res.data.code === 0) {
                                this.getData();
                                this.dialogTableVisible = false;
                                this.$utils.message();
                            } else {
                                this.$utils.message({
                                    type: 'error',
                                    message: res.data.message
                                })
                            }
                        })
                        //新增
                    } else {
                        let data = {
                            cn_name: this.form.cn_name,
                            demo: this.form.demo,
                            order_no: this.form.order_no
                        }
                        this.$axios.post("/p/unit/add", this.$qs(data)).then((res) => {
                            if (res.data.code === 0) {
                                this.getData();
                                this.dialogTableVisible = false;
                                this.$utils.message();
                            } else {
                                this.$utils.message({
                                    type: 'error',
                                    message: res.data.message
                                })
                            }
                        })
                    }
                }
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        submit();
                    } else {
                        return false;
                    }
                });
            },
            //编辑
            editorData(row, index) {
                this.$axios
                    .post(
                        "/p/unit/read",
                        this.$qs({
                            uuid: row.uuid,
                        })
                    )
                    .then(res => {
                        if (res.data.code === 0) {
                            this.form = res.data.data
                        }
                    });
                this.dialogTableVisibleType = true;
                this.dialogTableVisible = true;
                setTimeout(() => {
                    this.$dialogHeight();
                    this.$refs['form'].clearValidate();
                }, 100)
            },
            //  页码
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.getData();
                this.tableLoading = true;
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.currentPage = val;
                this.getData();
                this.tableLoading = true;
            },
            /*表格选中*/
            tableSelectionChange(val) {
                this.tableDataSelectArr = val;
            },
            //改变状态
            ChangeState(row) {
                this.tableLoading = true;
                this.$axios
                    .post(
                        "/p/unit/able",
                        this.$qs({
                            uuid: row.uuid
                        })
                    )
                    .then(response => {
                        if (response.data.code === 0) {
                            this.status = row.status
                            this.$message({
                                message: response.data.message,
                                type: "success"
                            });
                            this.getData()
                            this.tableLoading = false;
                        } else {
                            this.$message({
                                message: response.data.message,
                                type: "error"
                            });
                        }
                    });
            },
            //删除
            delMoreData() {
                this.$confirm("确定要删除此行数据吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true
                }).then(() => {
                    let arr = [];
                    this.tableDataSelectArr.forEach((i, k) => {
                        arr.push(i.uuid);
                    });
                    this.$axios
                        .post(
                            "/p/unit/del",
                            this.$qs({
                                uuids: arr.join(",")
                            })
                        )
                        .then(res => {
                            if (res.data.code === 0) {
                                // 解决跳页后删除本页最后一条数据查询不跳转问题
                                if (this.currentPage * 10 - 9 === this.total) {
                                    this.currentPage -= 1;
                                }
                                if (this.currentPage === 0) {
                                    this.currentPage = 1
                                }
                                this.getData();
                                this.$utils.message();
                            } else {
                                this.$utils.message({
                                    type: "error",
                                    message: res.data.message
                                });
                            }
                        });
                });
            },
            /*表格单行删除*/
            delTableData(row) {
                this.$confirm('确定要删除此行数据吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$axios.post("/p/unit/del", this.$qs({
                        uuids: row.uuid
                    })).then((res) => {
                        if (res.data.code === 0) {
                            this.$utils.message();
                            this.getData();
                        } else {
                            this.$utils.message({
                                type: 'error',
                                message: res.data.message
                            })
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
                })
            },
            //导出
            expor() {
                const numberName = this.selectArr.inputType == '1' ? '&personnel_umber=' + this.input4 : this.selectArr.inputType == '2' ? "&name=" + this.input4 : '';
                window.open(this.$store.state.url + '/p/staff/export/?department_uuid=' + this.$route.query.uuid + '&sex=' + this.selectArr.sex
                    + '&sport_uuid=' + this.selectArr.type + '&sport_event_uuid=' + this.selectArr.project + '&station_uuid=' + this.selectArr.jobs
                    + '&grade=' + this.selectArr.grade + numberName)

            },
        }
    };
</script>


<style lang='scss' scoped>
    .table-pagination {
        text-align: right;
        margin-top: 10px;

        /deep/ span {
            float: left;
        }
    }

    .child-top {
        .input-from {
            float: left;
            min-width: 850px;

            .left-child-input {
                .iconfont iconsousuo {
                    background: #fff;
                }
            }

            & > .el-select {
                width: 150px;
                float: left;
                margin-top: 10px;

                .el-input__inner {
                    border-radius: 0;
                    border-right: transparent;
                }

                .el-input__inner:focus {
                    border-right: 1px solid #409eff;
                }
            }


            .el-select:nth-child(4) {
                .el-input__inner:focus {
                    border-right: 0.052083rem solid #409eff;
                }
            }

            .el-select:nth-child(5) {
                .el-input__inner {
                    border-right: 0;
                }

                .el-input__inner:focus {
                    border-right: 0.052083rem solid #409eff;
                }
            }

            .el-select:nth-child(6) {
                width: 120px;
                margin-left: 10px;

                .el-input__inner {
                    border-radius: 0px 4px 4px 0;
                }
            }

            .el-select:nth-child(1) {
                .el-input__inner {
                    border-radius: 4px 0 0 4px;
                }
            }

            .el-input.el-input--suffix.left-child-input {
                float: left;
                width: 200px;

                .el-input__inner {
                    border-radius: 0 4px 4px 0;
                }
            }
        }

        .operation-button {
            // float: left;

            .el-button {
                margin-right: 0;
            }
        }
    }

    .el-input-group__append {
        .el-button .el-button--default {
            color: #fff;
            background-color: #409eff;
            border-color: #409eff;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .ModifyDoalog {
        margin-top: -20px;
        padding: 0 0 0 20px;

        & > p {
            font-size: 20px;
            color: #3B3B3B;
            text-align: center;
            margin-bottom: 20px;
        }

        .el-form {
            padding-right: 30px;
        }
    }
</style>
